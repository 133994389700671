import moment from 'moment';
import { partition } from 'ramda';
import { ROUTES } from 'constants/routes';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { Property, PropertyOffers, Tenure } from 'types/property';
import { SavillsSearchCriteria } from 'types/savills';
import { OfferStatus } from 'views/Vendor/types';
import { getOfferTag } from 'components/Offers/helpers';
import { getBounds, getCenter } from 'components/ApplicantRecordCard/helpers';

import {
  ApplicantOffer,
  ApplicantPropertyOffers,
  ApplicantRecord,
  OfferType,
  ReapitApplicantOffer,
  ReapitArea,
  Viewing,
} from './types';

export const getPathParts = (pathname: string) => {
  return pathname.replace(/\//g, ' /').split(' ');
};

export const parseNavItems = (
  offers: PropertyOffers[],
  viewings: Viewing[],
  pathname: string,
  t9n: T9nHubResource,
  hideOffers: boolean,
  hideViewings: boolean,
) => {
  return [
    {
      key: 'searches',
      value: t9n.Applicant.NavItems.Searches,
      link: ROUTES.APPLICANT.ROOT,
      count: 0,
      isactive: pathname === ROUTES.APPLICANT.ROOT || pathname === ROUTES.HOME,
      isHidden: false,
    },
    {
      key: 'viewings',
      value: t9n.Applicant.NavItems.Viewings || 'Viewings',
      link: ROUTES.APPLICANT.VIEWINGS,
      count: 0,
      isactive: pathname === ROUTES.APPLICANT.VIEWINGS,
      isHidden: viewings?.length === 0 || hideViewings,
    },
    {
      key: 'offers',
      value: t9n.Applicant.NavItems.Offers,
      link: ROUTES.APPLICANT.OFFERS,
      count: 0,
      isactive: pathname === ROUTES.APPLICANT.OFFERS,
      isHidden: offers?.length === 0 || hideOffers,
    },
  ];
};

export function recursivelyRemoveTypename(criteria: SavillsSearchCriteria) {
  return JSON.parse(JSON.stringify(criteria), (key, value) => {
    return key === '__typename' ? undefined : value;
  });
}

export const formatApplicantData = async (
  applicants?: ApplicantRecord[],
  isScriptLoaded?: boolean,
) => {
  if (!applicants || applicants.length === 0) {
    return [];
  }

  if (!isScriptLoaded || !window.google) {
    return applicants;
  }
  const updatedApplicants = await Promise.all(
    applicants.map(async (applicant) => {
      const { locationOptions, locationType } = applicant;
      if (
        locationType === 'addresses' &&
        locationOptions &&
        locationOptions?.length > 0
      ) {
        return {
          ...applicant,
          map: [
            {
              name: locationOptions[0],
              type: locationType,
              area: locationOptions,
              geocode: await getBounds(locationOptions[0]),
            },
          ],
        };
      }

      if (
        locationType === 'areas' &&
        applicant.map &&
        applicant.map?.length > 0
      ) {
        const updatedMap = await Promise.all(
          applicant.map.map(async (area: ReapitArea) => {
            return {
              ...area,
              geocode: await getCenter(area.area as unknown as string[]),
            };
          }),
        );
        return {
          ...applicant,
          map: updatedMap,
        };
      }
      return applicant;
    }),
  );

  return updatedApplicants;
};

export const getUpcomingViewings = (viewings: Viewing[]) => {
  return viewings.filter(
    (viewing) =>
      moment(viewing.start).isAfter(moment()) &&
      !moment(viewing.start).isSame(moment(), 'day') &&
      !viewing.isCancelled,
  );
};

export const getPastViewings = (viewings: Viewing[]) => {
  return viewings.filter(
    (viewing) =>
      moment(viewing.start).isBefore(moment()) || viewing.isCancelled,
  );
};

export const getTodayViewings = (viewings: Viewing[]) => {
  return viewings.filter(
    (viewing) =>
      moment(viewing.start).isSame(moment(), 'day') &&
      moment(viewing.start).isAfter(moment()) &&
      !viewing.isCancelled,
  );
};

export const filterOffers = (offers: ReapitApplicantOffer[]) => {
  return offers.filter((offer) => offer.property?.tenure === Tenure.Buy);
};

export const formatOffer = (
  offer: ReapitApplicantOffer,
  t9n: T9nHubResource,
) => {
  const { date, amount, status, negotiator, currency } = offer;

  return {
    ...offer,
    tag: getOfferTag(status, t9n, true),
    date: {
      dueDate: new Date(date),
      isExpired: status === OfferStatus.Rejected,
    },
    price: amount,
    currency,
    negotiator: {
      name: negotiator?.name,
      telephone: negotiator?.workPhone || offer.negotiator?.mobilePhone,
      email: negotiator?.email,
      image: negotiator?.profileImageUrl,
    },
  } as ApplicantOffer;
};

export const groupApplicantOffers = (
  offers: ReapitApplicantOffer[],
  t9n: T9nHubResource,
) => {
  return offers.reduce(
    (acc: ApplicantPropertyOffers[], offer: ReapitApplicantOffer) => {
      if (!offer.property) return acc;

      const formattedOffer = formatOffer(offer, t9n);
      const propertyExists = acc.find(
        (applicantOffer) =>
          applicantOffer.property?.reapitId === offer.property?.reapitId,
      );

      if (propertyExists) {
        const [matchingProperty, otherProperties] = partition(
          (applicantOffer) =>
            applicantOffer.property?.reapitId === offer.property?.reapitId,
          acc,
        );
        const allOffers = [...matchingProperty[0].offers, formattedOffer];
        const hasAcceptedOffer = allOffers.some((item) => {
          return item.status === OfferStatus.Accepted;
        });
        const hasActiveOffer = allOffers.some((item) => {
          return (
            item.status === OfferStatus.Pending ||
            item.status === OfferStatus.Accepted
          );
        });

        return [
          ...otherProperties,
          {
            ...matchingProperty[0],
            offers: allOffers,
            isAccepted: hasAcceptedOffer,
            type: hasActiveOffer ? OfferType.ACTIVE : OfferType.ARCHIVED,
          },
        ];
      }

      const isActive =
        offer.status === OfferStatus.Pending ||
        offer.status === OfferStatus.Accepted;

      return [
        ...acc,
        {
          property: offer.property as Property,
          offers: [formattedOffer],
          isAccepted: offer.status === OfferStatus.Accepted,
          type: isActive ? OfferType.ACTIVE : OfferType.ARCHIVED,
        },
      ];
    },
    [] as ApplicantPropertyOffers[],
  );
};

export const formatOffers = (
  offers: ReapitApplicantOffer[],
  t9n: T9nHubResource,
  type: OfferType,
) => {
  const filteredOffers = filterOffers(offers);
  const groupedOffers = groupApplicantOffers(filteredOffers, t9n);
  return groupedOffers.filter((offer) => offer.type === type);
};

export const getPageTitle = (pathname: string, t9n: T9nHubResource) => {
  switch (pathname) {
    case ROUTES.APPLICANT.SAVED_PROPERTIES:
      return t9n.PageTitles.MySearches;
    case ROUTES.APPLICANT.ROOT:
      return t9n.Applicant.Title;
    case ROUTES.APPLICANT.VIEWINGS:
      return t9n.Applicant.Viewings.Title;
    case ROUTES.APPLICANT.OFFERS:
      return t9n.Applicant.Offers.Title;
    default:
      return t9n.Applicant.Title;
  }
};
