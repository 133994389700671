import moment from 'moment';

import { SlimProperty } from 'types/app';
import { ReapitDocument, ReapitEntityTypes } from 'views/Documents/types';
import { FilterDateKeys, FilterKeys } from './constants';
import { FiltersConfig } from './types';

export const getFilterFunctions = (
  filtersConfig: FiltersConfig,
  lettingProperties: SlimProperty[],
) => {
  const filterFunctions = [];

  if (filtersConfig[FilterKeys.PROPERTY]) {
    filterFunctions.push((doc: ReapitDocument) => {
      return (
        (doc.associatedType === ReapitEntityTypes.PROPERTY &&
          doc.associatedId === filtersConfig[FilterKeys.PROPERTY]) ||
        lettingProperties
          .find(
            (property) => property.id === filtersConfig[FilterKeys.PROPERTY],
          )
          ?.tenancyDetails?.find((tenancy) => tenancy.id === doc.associatedId)
      );
    });
  }
  if (filtersConfig[FilterKeys.TENANCY]) {
    filterFunctions.push((doc: ReapitDocument) => {
      return (
        doc.associatedId === filtersConfig[FilterKeys.TENANCY] &&
        doc.associatedType === ReapitEntityTypes.TENANCY
      );
    });
  }

  if (filtersConfig[FilterKeys.TYPE]) {
    filterFunctions.push((doc: ReapitDocument) => {
      return doc.category === filtersConfig[FilterKeys.TYPE];
    });
  }
  if (
    filtersConfig[FilterKeys.DATE][FilterDateKeys.TO] &&
    filtersConfig[FilterKeys.DATE][FilterDateKeys.FROM]
  ) {
    filterFunctions.push((doc: ReapitDocument) => {
      const docDate = moment(doc.modified);
      return (
        docDate.isBetween(
          filtersConfig[FilterKeys.DATE][FilterDateKeys.FROM],
          filtersConfig[FilterKeys.DATE][FilterDateKeys.TO],
          'day',
          '[]',
        ) || docDate.isSame(filtersConfig[FilterKeys.DATE][FilterDateKeys.FROM])
      );
    });
  }
  return filterFunctions;
};
